import { Avatar, Box, Divider, Paper } from "@mui/material";
import Button from "@openup/shared/components/Button/Button";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getTranslatedThemeName } from "@src/utils/languageHelpers";
import {
  usePsychologistQuery,
  usePsychologistThemesQuery,
  useExpertDetailsMutation,
  resyncCalendar,
} from "@src/queries/experts";
import {
  psychologistGenderTagName,
  psychologistLanguageTagName,
  psychologistStatusTagName,
  sessionTypeTagName,
  useUserTagsQuery,
} from "@src/queries/tags";
import { formatNameAvatar } from "@src/utils/formatting";
import authenticationService from "@src/services/authentication.service";
import { useClientUnlockMutation } from "@src/queries/clients";
import {
  WithSnackbarProps,
  withSnackbar,
} from "@src/components/SnackBarComponent";
import { withSuspense } from "./wrappers/Suspendable";
import UpdatePsychologistTagsDialog from "./UpdatePsychologistDialog";

interface PsychologistDetailsProps extends WithSnackbarProps {
  viewUpdated: boolean;
  setViewUpdated: React.Dispatch<React.SetStateAction<boolean>>;
  psychologistId: string;
  className?: string;
  refetchPsychologistsList?: () => void;
  isEditAllowed: boolean;
}

const PsychologistDetails: React.FC<PsychologistDetailsProps> = ({
  viewUpdated,
  setViewUpdated,
  psychologistId,
  className = "",
  refetchPsychologistsList = undefined,
  isEditAllowed,
  snackbarShowMessage,
}) => {
  const { data: psychologist } = usePsychologistQuery(psychologistId);
  const { psychologistThemes } = usePsychologistThemesQuery(psychologistId);
  const { t } = useTranslation();
  const { isSupport } = authenticationService;
  const { mutateAsync: unlockMutation } = useClientUnlockMutation();
  const { mutateAsync: setTwentyFourSevenMutation } =
    useExpertDetailsMutation();

  const { userTags: userPsychologistStatusTags } = useUserTagsQuery(
    psychologistId,
    psychologistStatusTagName,
  );
  const { userTags: userSessionTypeTags } = useUserTagsQuery(
    psychologistId,
    sessionTypeTagName,
  );
  const { userTags: userLanguageTags } = useUserTagsQuery(
    psychologistId,
    psychologistLanguageTagName,
  );
  const { userTags: userGenderTags } = useUserTagsQuery(
    psychologistId,
    psychologistGenderTagName,
  );

  const [showDialog, setShowDialog] = useState(false);
  const [resyncCalendarLoading, setResyncCalendarLoading] = useState(false);
  const [unlockUserLoading, setUnlockUserLoading] = useState(false);
  const [twentyFourSevenLoading, setTwentyFourSevenLoading] = useState(false);

  useEffect(() => {
    setViewUpdated(!viewUpdated);
  }, [userPsychologistStatusTags, userSessionTypeTags, userLanguageTags]);

  const handleUnlockUser = async (
    event: React.MouseEvent<HTMLButtonElement>,
    userId,
  ) => {
    event.stopPropagation();
    setUnlockUserLoading(true);
    try {
      await unlockMutation(userId);
      snackbarShowMessage("", "success");
    } catch (error) {
      snackbarShowMessage("", "error");
    } finally {
      setUnlockUserLoading(false);
    }
  };
  const handleResyncCalendar = async (
    event: React.MouseEvent<HTMLButtonElement>,
    expert,
  ) => {
    event.stopPropagation();
    setResyncCalendarLoading(true);

    try {
      await resyncCalendar(expert.id);
      snackbarShowMessage("", "success");
    } catch (error) {
      snackbarShowMessage(
        error.code === "400"
          ? "Sync failed as users' token has expired.  Ask user to re-sync their Google calendar themselves"
          : "Re-sync failed",
        "error",
      );
    } finally {
      setResyncCalendarLoading(false);
    }
  };
  const handleToggleTwentyFourSeven = async (
    event: React.MouseEvent<HTMLButtonElement>,
    expert,
  ) => {
    event.stopPropagation();
    setTwentyFourSevenLoading(true);

    try {
      await setTwentyFourSevenMutation({
        userId: expert.id,
        worksTwentyFourSeven: !expert.worksTwentyFourSeven,
      });
      snackbarShowMessage("", "success");
    } catch (error) {
      snackbarShowMessage("", "error");
    } finally {
      setTwentyFourSevenLoading(false);
    }
  };
  const dividerClass = "mt-5 mb-5 bg-white opacity-20";
  const captionsClass = "cap1 text-blue-500 mb-2";
  const textClass = "body2 text-white";
  return (
    <Paper
      className={`px-4 py-3 bg-indigo-800 min-w-[265px] ${className}`}
      elevation={0}
    >
      <Avatar className="w-[132px] h-[132px] mb-5">
        {formatNameAvatar(psychologist.firstName, psychologist.lastName)}
      </Avatar>
      <Box className="mb-4 subtitle1 text-white">
        {psychologist.firstName},{psychologist.lastName}
      </Box>
      <p className={textClass}>{psychologist.email}</p>
      <Divider className={dividerClass} />
      <p className={captionsClass}>{t("Tags.PsychologistStatusTagsTitle")}</p>
      <div className={textClass}>
        {userPsychologistStatusTags?.map((tag) => tag.description)}
      </div>
      <Divider className={dividerClass} />
      <p className={captionsClass}>{t("Tags.SessionTypeTagsTitle")}</p>
      <div className={textClass}>
        {userSessionTypeTags?.map(
          (tag, index) => (index ? ", " : "") + tag.description,
        )}
      </div>
      <Divider className={dividerClass} />
      <p className={captionsClass}>{t("Tags.LanguageTagsTitle")}</p>
      <div className={textClass}>
        {userLanguageTags?.map(
          (tag, index) => (index ? ", " : "") + tag.description,
        )}
      </div>
      <Divider className={dividerClass} />
      <p className={captionsClass}>{t("Common.Gender")}</p>
      <div className={textClass}>
        {userGenderTags?.map(
          (tag, index) => (index ? ", " : "") + tag.description,
        )}
      </div>
      <Divider className={dividerClass} />
      <p className={captionsClass}>{t("SharedStrings.Themes")}</p>
      <div className={textClass}>
        {psychologistThemes?.map(
          (tag, index) =>
            (index ? ", " : "") + t(getTranslatedThemeName(t, tag.key || null)),
        )}
      </div>
      <Divider className={dividerClass} />
      {isSupport() && psychologist.isLockedOut && (
        <Button
          variant="primary"
          data-cy="lock-account"
          onClick={(event) => handleUnlockUser(event, psychologist.id)}
          loading={unlockUserLoading}
        >
          <span className="w-full mb-4">{t("Common.UnlockAccount")}</span>
        </Button>
      )}
      {isSupport() && (
        <>
          <Button
            variant={
              psychologist?.worksTwentyFourSeven ? "tertiary" : "primary"
            }
            disabled={!psychologist?.hasLinkedCalendar}
            data-cy="247-toggle-button"
            onClick={(event) =>
              handleToggleTwentyFourSeven(event, psychologist)
            }
            className={`${
              psychologist?.worksTwentyFourSeven
                ? "outline-white text-white"
                : ""
            } w-full px-0 mb-4`}
            classNameLoading="text-white"
            loading={twentyFourSevenLoading}
          >
            {t(
              psychologist?.worksTwentyFourSeven
                ? "PsychologistDetails.Disable247"
                : "PsychologistDetails.Enable247",
            )}
          </Button>

          <Button
            variant="primary"
            disabled={!psychologist?.hasLinkedCalendar}
            data-cy="resync-calendar-button"
            onClick={(event) => handleResyncCalendar(event, psychologist)}
            className="w-full px-0 mb-4"
            loading={resyncCalendarLoading}
          >
            {t("PsychologistDetails.ResyncCalendar")}
          </Button>
        </>
      )}
      {isEditAllowed && (
        <Button
          variant="tertiary"
          onClick={() => setShowDialog(true)}
          className="outline-white text-white w-full"
          disabled={!psychologist?.id}
        >
          {t("Common.EditPsychologist")}
        </Button>
      )}
      {psychologist?.id && (
        <UpdatePsychologistTagsDialog
          isEdit
          open={showDialog}
          refetchPsychologistsList={refetchPsychologistsList}
          onClose={() => {
            setShowDialog(false);
          }}
          client={psychologist}
        />
      )}
    </Paper>
  );
};

export default withSnackbar(
  withSuspense(PsychologistDetails, {
    nothingFoundTranslationKey: "Client.Details.NotFound",
  }),
);
