import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import {
  HighlightOff as HighlightOffIcon,
  TodayOutlined as TodayOutlinedIcon,
  PlayCircleOutline as PlayCircleOutlineIcon,
  Update as UpdateIcon,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ConsultActions, STATUS } from "@src/constants";
import {
  formatFirstUpperCase,
  formatSessionType,
  formatStandardDate,
} from "@src/utils/formatting";
import { getConsultType, isInternallyScheduled } from "@src/utils/helpers";
import { ConsultWithShortNotesResponse } from "@src/models/Consults/ConsultWithShortNotesResponse";
import Button from "@openup/shared/components/Button/Button";
import TypographyWithIcon from "@openup/shared/components/TypographyWithIcon/TypographyWithIcon";
import { IconSessionLanguage } from "@src/components/Icons/IconSessionLanguage";
import Spinner from "@openup/shared/components/Spinner/Spinner";

interface FutureSessionsTableProps {
  consults: ConsultWithShortNotesResponse[];
  handleClickScheduleSession: () => void;
  canJoinSession: boolean;
  isLoading: boolean;
}

const FutureSessionsTable: React.FC<FutureSessionsTableProps> = ({
  consults,
  handleClickScheduleSession,
  canJoinSession,
  isLoading,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const columns = [
    {
      id: t("Common.Date"),
      minWidth: 130,
    },
    {
      id: t("Consults.Psychologist"),
      minWidth: 240,
    },
    {
      id: t("Common.Type"),
      minWidth: 120,
    },
    {
      id: "",
      minWidth: 32,
    },
    {
      id: t("Common.Status"),
      minWidth: 120,
    },
  ];

  const handleClickStartSession = (event, consult) => {
    event.stopPropagation();
    window.open(consult.psychologistUrl, "_blank", "noopener");
  };
  const handleClickRescheduleSession = (event, consult) => {
    event.stopPropagation();
    navigate(`/my-sessions/consult/${consult.id}/time-picker`);
  };
  const handleClickCancelSession = (event, consult) => {
    event.stopPropagation();
    navigate(`/my-sessions/consult/${consult.id}/cancel-session`);
  };

  const ctaButton = (consult, action) => {
    let title;
    let icon;
    let onClick;
    let isWhite = true;
    switch (action) {
      case ConsultActions.JOIN:
        title = t("Common.JoinSession");
        icon = (
          <PlayCircleOutlineIcon
            fontSize="medium"
            data-cy="client-join-session-button"
          />
        );
        onClick = handleClickStartSession;
        isWhite = false;
        break;
      case ConsultActions.RESCHEDULE:
        title = t("Common.RescheduleSession");
        icon = (
          <UpdateIcon fontSize="medium" data-cy="reschedule-session-button" />
        );
        onClick = handleClickRescheduleSession;
        break;
      case ConsultActions.CANCEL:
        title = t("Common.CancelSession");
        icon = (
          <HighlightOffIcon fontSize="medium" data-cy="cancel-session-button" />
        );
        onClick = handleClickCancelSession;
        break;
    }

    const extraStyling = isInternallyScheduled(consult)
      ? "bg-indigo-800 hover:bg-blue-500 group-hover:opacity-70 border-solid"
      : "bg-slate-200 hover:cursor-auto";
    const colorBackground = isWhite
      ? "bg-white text-indigo-800"
      : "bg-blue-500 text-white";

    return (
      <Tooltip title={title} placement="top">
        <button
          type="button"
          className={`w-8 h-8 flex items-center justify-center cursor-pointer mr-1 rounded border-2 hover:opacity-50 ${extraStyling} ${colorBackground}`}
          onClick={(event) => onClick(event, consult)}
        >
          {icon}
        </button>
      </Tooltip>
    );
  };

  const statusCell = (consult) => {
    return (
      <TableCell>
        <Box display="flex" alignItems="center">
          <p className="pr-2 font-normal group-hover:text-blue-500">
            {t(`Consults.Status.${formatFirstUpperCase(consult.status)}`)}
          </p>
          {canJoinSession &&
            consult.status.toLowerCase() !==
              STATUS.CANCELED_BY_CLIENT.toLowerCase() &&
            consult.status.toLowerCase() !==
              STATUS.CANCELED_BY_OPENUP.toLowerCase() &&
            ctaButton(consult, ConsultActions.JOIN)}
          {consult.status.toLowerCase() === STATUS.SCHEDULED.toLowerCase() && (
            <>
              {ctaButton(consult, ConsultActions.RESCHEDULE)}
              {ctaButton(consult, ConsultActions.CANCEL)}
            </>
          )}
        </Box>
      </TableCell>
    );
  };

  const navigateToNotesPage = (consult) => {
    const notesAvailable =
      consult.status === STATUS.COMPLETED ||
      consult.status === STATUS.SCHEDULED;
    if (!notesAvailable || !canJoinSession) return;
    navigate(`/clients/${consult.userId}/session-notes/${consult.id}`);
  };

  return (
    <Paper className="mb-4 p-6">
      <Box className="flex flex-row justify-between mb-2">
        <TypographyWithIcon
          classNameText="h5"
          icon={<TodayOutlinedIcon className="mr-1" />}
        >
          {t("Client.Sessions.Future")}
        </TypographyWithIcon>
        <Button
          onClick={handleClickScheduleSession}
          data-cy="schedule-session"
          variant="quaternary"
        >
          <TypographyWithIcon
            classNameText="h5 text-sm tracking-wider underline"
            icon={<TodayOutlinedIcon className="mr-1" />}
          >
            {t("Client.Sessions.Schedule")}
          </TypographyWithIcon>
        </Button>
      </Box>
      {!isLoading && (
        <TableContainer>
          <Table data-cy="future-sessions">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    style={{ minWidth: column.minWidth }}
                  >
                    <p className="sub1">{column.id}</p>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {consults.map((consult) => (
                <TableRow
                  className="group"
                  role="checkbox"
                  tabIndex={-1}
                  data-cy={`consult-row-${consult.id}`}
                  key={consult.id}
                  onClick={() => navigateToNotesPage(consult)}
                >
                  <TableCell>
                    <span className="body1">
                      {formatStandardDate(
                        consult.startedAt,
                        "Europe/Amsterdam",
                      )}
                    </span>
                  </TableCell>
                  <TableCell>
                    <span className="body1">{consult.employeeFullName}</span>
                  </TableCell>
                  <TableCell>
                    <span className="body1">
                      {formatSessionType(getConsultType(consult))}
                    </span>
                  </TableCell>
                  <TableCell>
                    <IconSessionLanguage
                      language={consult.languageCode}
                      tooltip={t(`SessionLanguage.${consult.languageCode}`)}
                    />
                  </TableCell>
                  {statusCell(consult)}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {isLoading && (
        <div className="flex w-full justify-center">
          <Spinner />
        </div>
      )}
    </Paper>
  );
};

export default FutureSessionsTable;
