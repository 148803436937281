import dayjs from "dayjs";
import { useEffect, useState } from "react";
import authenticationService from "@src/services/authentication.service";
import AgendaSelector from "@src/components/AgendaSelectorDialog";
import { useClientConsultsQuery } from "@src/queries/consults";
import {
  defaultSessionDurationInMinutes,
  delayInMinutesBetweenSessionTables,
  pageSize,
} from "@src/constants";
import PsychologistSelectorDialog from "@src/components/PsychologistSelectorDialog";
import SpacesTable from "@src/views/ClientPage/SpacesTable";
import { useGetClientSpacesQuery } from "@src/queries/spaces";
import { ConsultWithShortNotesResponse } from "@src/models/Consults/ConsultWithShortNotesResponse";
import FutureSessionsTable from "./FutureSessionsTable";
import PastSessionsTable from "./PastSessionsTable";

interface ClientSessionsSectionProps {
  clientId: string;
  clientName: string;
  canAddSession?: boolean;
  viewNotesColumn?: boolean;
  canJoinSession?: boolean;
}

const ClientSessionsSection: React.FC<ClientSessionsSectionProps> = ({
  clientId,
  clientName,
  canAddSession = true,
  viewNotesColumn = true,
  canJoinSession = true,
}) => {
  const [spacesPage, setSpacesPage] = useState(0);

  const { data: consults, isFetching: isConsultsFetching } =
    useClientConsultsQuery(clientId);
  const { data: spaces } = useGetClientSpacesQuery(clientId, spacesPage);
  const [pastSessions, setPastSessions] = useState<
    ConsultWithShortNotesResponse[]
  >([]);
  const [futureSessions, setFutureSessions] = useState<
    ConsultWithShortNotesResponse[]
  >([]);
  const [showAgendaDialog, setShowAgendaDialog] = useState(false);
  const [showPsychologistPicker, setShowPsychologistPicker] = useState(false);
  const { isExpert, isHost } = authenticationService;

  useEffect(() => {
    if (!consults) return;
    const now = dayjs();
    const delay =
      defaultSessionDurationInMinutes + delayInMinutesBetweenSessionTables; // Time it takes to transition from one table to the other

    setPastSessions(
      consults.filter((consult) =>
        dayjs(consult.startedAt).add(delay, "m").isBefore(now),
      ),
    );
    setFutureSessions(
      consults.filter(
        (consult) => !dayjs(consult.startedAt).add(delay, "m").isBefore(now),
      ),
    );
  }, [consults]);

  const handleClickScheduleSession = () => {
    if (isExpert()) {
      setShowAgendaDialog(true);
    } else if (isHost()) {
      setShowPsychologistPicker(true);
    }
  };

  const onCloseDialog = () => {
    setShowAgendaDialog(false);
  };

  const handleSpacePaginate = (page: number) => {
    setSpacesPage(page);
  };

  return (
    <>
      <FutureSessionsTable
        consults={futureSessions}
        canJoinSession={canJoinSession}
        handleClickScheduleSession={handleClickScheduleSession}
        isLoading={isConsultsFetching}
      />
      <PastSessionsTable
        clientId={clientId}
        consults={pastSessions}
        canAddSession={canAddSession}
        viewNotesColumn={viewNotesColumn}
        isLoading={isConsultsFetching}
      />
      <SpacesTable
        spaces={spaces}
        clientName={clientName}
        page={spacesPage}
        onPaginate={handleSpacePaginate}
        pageSize={pageSize}
      />
      {showAgendaDialog && (
        <AgendaSelector
          clientId={clientId}
          open={showAgendaDialog}
          onClose={onCloseDialog}
        />
      )}
      {showPsychologistPicker && (
        <PsychologistSelectorDialog
          setIsOpen={setShowPsychologistPicker}
          clientId={clientId}
        />
      )}
    </>
  );
};

export default ClientSessionsSection;
