export const AVAILABLE_LANGUAGES = [
  { label: "EN", value: "en-GB" },
  { label: "NL", value: "nl-NL" },
];

export const AuthenticatorTwoFactorCodeRequestMethod = "AuthenticatorKey";

export const USER_AVAILABLE_LANGUAGES = [
  { label: "EN", value: "en-GB" },
  { label: "NL", value: "nl-NL" },
  { label: "DE", value: "de-DE" },
  { label: "FR", value: "fr-FR" },
  { label: "ES", value: "es-ES" },
];

export const GenderIdentifier = "Gender";
export const GENDER = {
  MALE: "male",
  FEMALE: "female",
  UNKNOWN: "unknown",
};

export const AVAILABLE_QUESTIONNAIRES = [{ label: "SQ48", value: "SQ48" }];

export const apiPaths = {
  getTwoFactorRegistration: "v1/users/2fa/authenticator/registration-info",
  enableTwoFactorLogin: "v1/users/2fa/authenticator/enable",
  verifyTwoFactorCode: "v1/users/2fa/validate",
  grantCalendarAccess: "v1/google-calendar/grant-access",
  createChat: "v1/chat/channel",

  getSessionType: "v1/tags/SessionType",
  getSessionLanguage: "v1/tags/SessionLanguage",

  profile: "v2/users/profile",
  login: "v2/users/login",
  logout: "v1/users/logout",
  refreshToken: "v1/users/refresh-token",

  externalLogin: "v2/external-login/login-code-flow",
  externalSignUp: "v2/external-login/register-code-flow",

  providerChallenge: "v1/external-login/provider-challenge",
  reschedule: "v1/reschedule", // this is meant for `${scheduler}/consult/${consultId}/reschedule`
  videoCalling: "v1/video-calling",
  spaces: "v1/spaces",
  psychologists: "v1/psychologists",
  psychologistManagement: "v1/psychologist-management",
  reassignPsychologist: "v1/psychologist-management/reassign-psychologist",
  makePsychologist: "v1/psychologist-management/make-psychologist",
  openupEmployees: "v1/psychologist-management/openup-employees",
  psychologistThemes: "v1/psychologists/themes",
  setPsychologistTheme: "v1/psychologist-management/set-psychologist-themes",
  psychologistConsults: "v1/psychologists/consults",
  psychologistAllConsults: "v1/consults/all-sessions",
  psychologistProfile: "v1/psychologists/profile",
  expertDetails: "v1/expert-details",
  clients: "v1/clients",
  myClients: "v1/clients/my",
  getGoogleCalendar: "v1/google-calendar/get-calendars",
  registerGoogleCalendar: "v1/google-calendar/register-calendar",
  employers: "v1/employers",
  consults: "v1/consults",
  clientConsults: "v1/client-consults",
  notifications: "v1/notifications",
  availableTimeSlots: "v1/scheduler/time-slots/available",
  scheduler: "v1/scheduler",
  timezones: "v1/scheduler/timezones",
  psychologistsTags: "v1/psychologists/tags",
  tags: "v1/tags",
  themes: "v1/themes",
  updateLanguage: "v1/users/update-language",
  myFeedbacks: "v1/psychologists/my-feedbacks",
  resyncCalendar: "v1/scheduler/resynchronize-calendar",
};

export const apiPathsWithParams = {
  getVideoCallToken: (roomId: string) =>
    `${apiPaths.videoCalling}/token?roomId=${roomId}`,
  uploadChatFile: (chatThreadId: string) =>
    `${apiPaths.videoCalling}/chat/${chatThreadId}/attachments`,
  getClientSpaces: (clientId: string) =>
    `${apiPaths.spaces}/client/${clientId}`,
  whoElseIsInCall: (roomId: string) =>
    `${apiPaths.videoCalling}/who-else-in-call?roomId=${roomId}`,
  getEmployerReferralToolkits: (employerId: string) =>
    `${apiPaths.employers}/${employerId}/referral-toolkit`,
  downloadEmployerRefferalToolkit: (employerId: string, fileName: string) =>
    `${apiPaths.employers}/${employerId}/referral-toolkit/files?fileName=${fileName}`,
  resyncCalendar: (calendarId: string) =>
    `${apiPaths.resyncCalendar}/${calendarId}`,
};

export const STATUS = {
  SCHEDULED: "Scheduled",
  CANCELED_BY_CLIENT: "CancelledByClient",
  CANCELED_BY_OPENUP: "CancelledByOpenUp",
  COMPLETED: "Completed",
  MISSED_BY_CLIENT: "MissedByClient",
  MISSED_BY_OPENUP: "MissedByOpenUp",
  MISSED_BY_Both: "MissedByBoth",
};
export const STATUS_SUBJECT_FOR_CHANGE = [
  STATUS.MISSED_BY_Both,
  STATUS.MISSED_BY_CLIENT,
  STATUS.MISSED_BY_OPENUP,
  STATUS.COMPLETED,
];

export const TIMEZONES = {
  AMSTERDAM: "Europe/Amsterdam",
  BERLIN: "Europe/Berlin",
  KIEV: "Europe/Kiev",
  LONDON: "Europe/London",
  MOSCOW: "Europe/Moscow",
  PARIS: "Europe/Paris",
  PRAGUE: "Europe/Prague",
  RIYADH: "Asia/Riyadh",
  NEW_YORK: "America/New_York",
};

export const AmsterdamTimezone = "Europe/Amsterdam";

export const RESPONSE_STATUS = {
  OK: 200,
  BAD_REQUEST: 400,
  INTERNAL_SERVER_ERROR: 500,
  NOT_FOUND: 404,
};

export const TAG_TYPES = {
  SESSION_LANGUAGE: "SessionLanguage",
  SESSION_TYPE: "SessionType",
  USER_STATUS: "Status",
  USER_MINDSET: "Mindset",
  PSYCHOLOGIST_LANGUAGE: "PsychologistLanguage",
  PSYCHOLOGIST_STATUS: "PsychologistStatus",
  PSYCHOLOGIST_THEME: "PsychologistTheme",
  GENDER: "Gender",
  TOPICS: "Topics",
};

export const STATUS_TYPE = {
  INACTIVE: "inactive",
  ACTIVE_NOT_ACCEPTING: "active_not_accepting",
  ACTIVE_ACCEPTING: "active_accepting",
};

export const SESSION_TYPE = {
  GENERAL: "general",
  MINDFULNESS: "mindfulness",
  MEDICAL: "medical",
  PHYSICAL_WELLBEING: "physical-wellbeing",
};

export const LANGUAGE_TYPES = {
  ARABIC: "ar-AR",
  MOROCCAN: "ar-MA",
  BOSNIAN: "bs-BA",
  GERMAN: "de-DE",
  GERMAN_CH: "de-CH",
  ENGLISH_US: "en-US",
  ENGLISH_GB: "en-GB",
  SPANISH: "es-ES",
  FRENCH: "fr-FR",
  CROATIAN: "hr-HR",
  ITALIAN: "it-IT",
  FLEMISH: "nl-BE",
  DUTCH: "nl-NL",
  CHINESE: "zh-CN",
  SERBIAN: "sr-RS",
  RUSSIAN: "ru-RU",
  TURKISH: "tr-TR",
  PORTUGUESE: "pt-PT",
  UKRAINIAN: "uk-UA",
  POLISH: "pl-PL",
  BULGARIAN: "bg-BG",
  DANISH: "da-DK",
  SWEDISH: "sv-SE",
  FINNISH: "fi-FI",
  SOMALI: "so-SO",
  NORWEGIAN: "nb-NO",
  HUNGARIAN: "hu-HU",
  SLOVAK: "sk-SK",
  GREEK: "el-GR",
  EGYPTIAN: "ar-EG",
  TUNISIAN: "ar-TN",
  CZECH: "cs-CZ",
  SLOVENIAN: "sl-SL",
  AFRIKAANS: "af-ZA",
  MAURITIAN: "mfe-MU",
  JAPANESE: "ja-JP",
  INDONESIAN: "id-ID",
  KOREAN: "ko-KR",
  ROMANIAN: "ro-RO",
};

export const THEME_KEY = {
  CONFIDENCE: "Confidence",
  STRESS: "Stress",
  WORK: "Work",
  PURPOSE: "Purpose",
  RELATIONSHIPS: "Relationships",
  HEALTH: "Health",
  SLEEP: "Sleep",
  MINDFULNESS: "Mindfulness",
  BIOMETRICS: "Biometrics",
  MENTAL_HEALTH: "MentalHealth",
  PERSONAL_INFORMATION: "PersonalInformation",
  MEDICAL_HISTORY: "MedicalHistory",
  INTOXICATIONS: "Intoxications",
  NUTRITION: "Nutrition",
  PHYSICAL_ACTIVITY: "PhysicalActivity",
  SLEEP_SATISFACTION: "SleepSatisfaction",
  BREATHING: "Breathing",
  DAILY_ENERGY: "DailyEnergy",
  MOTIVATION: "Motivation",
  ENERGY: "Energy",
  EXERCISE: "Exercise",
  MEDICAL: "medical",
};

export const consultNotesTemplateFirstConsult =
  "-Request for help/goal: \n-Issues (complaints/opportunities): \n-Circumstances: \n-Personal style (name at least 1 strength): ";
export const consultNotesTemplateFollowUpConsult =
  "-Discussed during session: \n-Advice/tip/assignment: \n-Next steps agreed upon: \n-Other: ";

export const pageSize = 10;
export const smallTablePageSize = 5;
export const enterKey = 13;
export const backspaceKey = 8;

export const delayInMinutesBetweenSessionTables = 120;
export const defaultSessionDurationInMinutes = 30;

export const PROFILE_VALUES = {
  PROFILE: "profile",
  LOGOUT: "logout",
};
export const timeSnackbar = 2000;
export const THEME_KEYS = {
  CONFIDENCE: "Confidence",
  STRESS: "Stress",
  WORK: "Work",
  PURPOSE: "Purpose",
  RELATIONSHIPS: "Relationships",
  HEALTH: "Health",
  SLEEP: "Sleep",
  MINDFULNESS: "Mindfulness",
  MEDICAL: "medical",
};

export const QUESTIONS_KEYS = {
  CONFIDENCE: "self-confidence",
  STRESS: "stress-and-worry",
  WORK: "work-performance",
  PURPOSE: "meaning",
  RELATIONSHIPS: "social-relationships",
  HEALTH: "lifestyle",
  SLEEP: "sleep",
  MINDFULNESS: "mindfulness",
  MEDICAL: "medical",
};

export const HELP_NEEDED_VALUES = {
  TRUE: "true",
  NO_REFER_TO_IPRACTICE: "noReferToiPractice",
  NO_REFER_TO_OTHER_PROVIDER: "noReferToOtherProvider",
  NO_CLIENT_AT_RISK: "noClientAtRisk",
};

export const NEXT_STEPS = {
  WEEKS: "weeks",
  MONTHS: "months",
  BREAK: "break",
};

export const ConsultActions = {
  JOIN: "join",
  RESCHEDULE: "reschedule",
  CANCEL: "cancel",
};
export const APPLICATION_INSIGHTS_APP_NAME = "OpenUp.Admin";

export const ConsultReviewQuestionsKeys = {
  Question1: "Consult.Review.Question1",
  Question2: "Consult.Review.Question2",
  Question3: "Consult.Review.Question3",
  Question4: "Consult.Review.Feedback",
};
