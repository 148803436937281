import { useMutation, useQuery, useQueryClient, useQueries } from "react-query";
import axios from "axios";
import { apiPaths } from "@src/constants";
import { queryIdentifiers } from "@src/utils/constants";
import { ExpertTag } from "@src/models/ExpertTag";

export const sessionTypeTagName = "SessionType";
export const sessionLanguageTagName = "SessionLanguage";
export const psychologistStatusTagName = "PsychologistStatus";
export const psychologistLanguageTagName = "PsychologistLanguage";
export const topicsTagName = "Topics";
export const psychologistGenderTagName = "Gender";

const consultsIdentifier = "consults";

const fetchTags = async (tagType) => {
  const response = await axios.get(`${apiPaths.tags}/${tagType}`);
  return response.data;
};

export const useMultipleTagsQueries = (tagTypes: string[]) => {
  const queries = tagTypes.map((tagType) => {
    return {
      queryKey: [queryIdentifiers.tags, tagType],
      queryFn: () => fetchTags(tagType),
    };
  });
  const results = useQueries(queries);

  const data = results.map((result) => result.data);

  // Handle loading and error states
  const isLoading = results.some((result) => result.isLoading);
  const error = results.find((result) => result.error)?.error;

  const taggedData = tagTypes.reduce((acc, tagType, index) => {
    acc[tagType] = data[index];
    return acc;
  }, {});

  return { taggedData, isLoading, error };
};

export const useUserTagsQuery = (userId, tagType) => {
  const queryClient = useQueryClient();

  const { data: userTags } = useQuery(
    [apiPaths.psychologistManagement, userId, tagType],
    async () => {
      const { data } = await axios.get(
        `${apiPaths.psychologistManagement}/${userId}/${tagType}`,
      );
      return data;
    },
  );

  const { mutateAsync: saveTags } = useMutation(
    async (tags) => {
      await axios.post(
        `${apiPaths.psychologistManagement}/${userId}/${tagType}`,
        tags,
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          apiPaths.psychologistManagement,
          userId,
          tagType,
        ]);
      },
    },
  );

  return {
    userTags,
    saveTags,
  };
};

export const useConsultTagsQuery = (clientId, consultId, tagType) => {
  const queryClient = useQueryClient();

  const { data: consultTags } = useQuery(
    [apiPaths.clients, clientId, consultsIdentifier, consultId, tagType],
    async () => {
      const { data } = await axios.get(
        `${apiPaths.clients}/${clientId}/${consultsIdentifier}/${consultId}/${tagType}`,
      );
      return data;
    },
    {
      enabled: !!(clientId && consultId),
    },
  );

  const { mutateAsync: saveTags } = useMutation(
    async ({ tags, id }: any) => {
      await axios.post(
        `${apiPaths.clients}/${clientId}/${consultsIdentifier}/${
          consultId || id
        }/${tagType}`,
        tags,
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          apiPaths.clients,
          clientId,
          consultsIdentifier,
          consultId,
          tagType,
        ]);
        queryClient.invalidateQueries([
          apiPaths.clients,
          clientId,
          consultsIdentifier,
        ]);
      },
    },
  );

  return {
    consultTags,
    saveTags,
  };
};

export const usePsychologistTagsQuery = () =>
  useQuery<ExpertTag[]>([apiPaths.psychologistsTags], async () =>
    axios
      .get(`${apiPaths.psychologistsTags}`)
      .then((result) =>
        result.data.map(
          (tag) =>
            ({
              tagName: tag.name,
              tagType: tag.tagType.name,
              description: tag.description,
            }) as ExpertTag,
        ),
      )
      .catch(() => {
        return undefined;
      }),
  );

export const usePsychologistTagsByIdQuery = (psychologistId) =>
  useQuery(
    [apiPaths.psychologistsTags, psychologistId],
    async () => {
      return axios
        .get(`${apiPaths.psychologistsTags}/${psychologistId}`)
        .then((result) => {
          return result.data.map((tag) => ({
            tagName: tag.name,
            tagType: tag.tagType.name,
            description: tag.description,
          }));
        })
        .catch(() => {
          return null;
        });
    },
    {
      enabled: !!psychologistId,
    },
  );
