import { Navigate } from "react-router";

import MyClientsView from "@src/views/MyClientsView";
import AdminDashboardLayout from "@src/layouts/main/AdminDashboardLayout";
import AuthorizedExternalView from "@src/views/auth/AuthorizedExternalView";

import LoginLayout from "@src/layouts/Login/LoginLayout";
import FlowLayout from "@src/layouts/Scheduler/FlowLayout";
import AllClientsView from "@src/views/AllClientsView";
import AllSessionsView from "@src/views/AllSessionsView";
import ClientDetailView from "@src/views/ClientPage/ClientDetailView";
import ClientThemeView from "@src/views/ClientPage/ClientThemeView";
import SessionNotesView from "@src/views/ClientPage/SessionNotes/SessionNotesView";
import HomeView from "@src/views/HomeView/HomeView";
import LoginView from "@src/views/Login/LoginView";
import MySessionsView from "@src/views/MySessionsView";
import PsychologistsView from "@src/views/PsychologistsView";
import ProfileView from "@src/views/profile/ProfileView";
import BookSessionFlow from "@src/views/sessions/BookSessionFlow";
import CancelSession from "@src/views/sessions/CancelSession/CancelSession";
import RescheduleSessionFlow from "@src/views/sessions/RescheduleSessionFlow";
import MakePsychologistCall from "@src/views/MakePsychologistCall";
import MyFeedbacksView from "@src/views/MyFeedbacksView";
import { OpenUpRoles } from "./services/OpenUpRoles";

const routes = (isLoggedIn: boolean, hasRole: (r) => boolean) => {
  const ensureLoggedInAndHasOneOfTheRequiredRoles = (
    component: JSX.Element,
    ...allowedRoles
  ) => {
    if (!isLoggedIn) return <Navigate to="/login" />;
    if (!allowedRoles.length || allowedRoles.some((r) => hasRole(r)))
      return component;
    return <Navigate to="/" />;
  };

  return [
    {
      path: "/login",
      element: <LoginLayout />,
      children: [
        {
          index: true,
          element: <LoginView />,
        },
        {
          path: ":step",
          element: <LoginView />,
        },
      ],
    },
    {
      path: "/authorized",
      element: <AuthorizedExternalView />,
    },
    {
      path: "/my-sessions/consult/:consultId",
      element: ensureLoggedInAndHasOneOfTheRequiredRoles(<FlowLayout />),
      children: [
        {
          path: "time-picker",
          element: <RescheduleSessionFlow />,
        },
        {
          path: "cancel-session",
          element: <CancelSession />,
        },
      ],
    },
    {
      path: "/all-sessions",
      element: ensureLoggedInAndHasOneOfTheRequiredRoles(
        <AdminDashboardLayout />,
      ),
      children: [
        {
          path: "/all-sessions",
          element: <AllSessionsView />,
        },
      ],
    },
    {
      path: "/book-session",
      element: ensureLoggedInAndHasOneOfTheRequiredRoles(<FlowLayout />),
      children: [
        {
          path: "/book-session/:clientId",
          element: <BookSessionFlow />,
        },
        {
          path: "/book-session/:clientId/:psychologistId",
          element: <BookSessionFlow />,
        },
      ],
    },
    {
      path: "/my-sessions",
      element: ensureLoggedInAndHasOneOfTheRequiredRoles(
        <AdminDashboardLayout />,
        OpenUpRoles.Expert,
      ),
      children: [
        {
          path: "/my-sessions",
          element: <MySessionsView />,
        },
      ],
    },
    {
      path: "/my-feedbacks",
      element: ensureLoggedInAndHasOneOfTheRequiredRoles(
        <AdminDashboardLayout />,
        OpenUpRoles.Expert,
      ),
      children: [
        {
          path: "/my-feedbacks",
          element: <MyFeedbacksView />,
        },
      ],
    },
    {
      path: "/",
      element: ensureLoggedInAndHasOneOfTheRequiredRoles(
        <AdminDashboardLayout />,
      ),
      children: [
        {
          path: "/clients/:clientId/session-notes/:consultId",
          element: ensureLoggedInAndHasOneOfTheRequiredRoles(
            <SessionNotesView />,
            OpenUpRoles.Expert,
          ),
        },
        {
          path: "/clients/:clientId/:themeName",
          element: <ClientThemeView />,
        },
        {
          path: "/clients/:clientId",
          element: <ClientDetailView />,
        },

        {
          path: "/clients",
          element: ensureLoggedInAndHasOneOfTheRequiredRoles(
            <MyClientsView />,
            OpenUpRoles.Manager,
            OpenUpRoles.Expert,
          ),
        },
        {
          path: "/all-clients",
          element: <AllClientsView />,
        },
        {
          path: "/",
          element: <HomeView />,
        },
        {
          path: "/profile",
          element: ensureLoggedInAndHasOneOfTheRequiredRoles(
            <ProfileView />,
            OpenUpRoles.Admin,
            OpenUpRoles.Expert,
          ),
        },
      ],
    },
    {
      path: "/psychologists",
      element: ensureLoggedInAndHasOneOfTheRequiredRoles(
        <AdminDashboardLayout />,
      ),
      children: [
        {
          path: "",
          element: <PsychologistsView />,
        },
        {
          path: ":psychologistsId",
          element: <PsychologistsView />,
        },
      ],
    },
    {
      path: "/video-call/:clientId/:consultId",
      element: ensureLoggedInAndHasOneOfTheRequiredRoles(
        <MakePsychologistCall />,
        OpenUpRoles.Expert,
      ),
    },
  ];
};

export default routes;
