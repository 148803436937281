import { Dialog } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import PsychologistSelectorTable from "@src/components/PsychologistSelectorTable";
import {
  useReassignPsychologistMutation,
  usePsychologistsQuery,
} from "@src/queries/experts";
import { Consult, Psychologist } from "@src/models";
import { smallTablePageSize } from "@src/constants";
import { DialogActionButtons, DialogHeader } from "./DialogToolkit";
import { withSnackbar, WithSnackbarProps } from "./SnackBarComponent";

interface SwapPsychologistDialogProps extends WithSnackbarProps {
  setIsOpen: (open: boolean) => void;
  consult: Consult;
}

const SwapPsychologistDialog: React.FC<SwapPsychologistDialogProps> = ({
  setIsOpen,
  consult,
  snackbarShowMessage,
}) => {
  const { t } = useTranslation();

  const [selectedPsychologist, setSelectedPsychologist] =
    useState<Psychologist | null>(null);
  const [search, setSearch] = useState<string>("");
  const [page, setPage] = useState(0);
  const [isFirstStepOpen, setIsFirstStepOpen] = useState(true);

  const {
    data: {
      data: allPsychologists = [],
      pagination: { total = 0, hasNextPage = false } = {},
    } = {},
    isFetching,
  } = usePsychologistsQuery({ page, limit: smallTablePageSize, search });

  const {
    mutateAsync: reassignPsychologist,
    isLoading: isReassigningPsychologist,
  } = useReassignPsychologistMutation();

  const onSelection = () => {
    if (!selectedPsychologist) {
      snackbarShowMessage(t("ReassignPsychologist.Error"));
      return;
    }
    setIsFirstStepOpen(false);
  };

  const onSubmit = async () => {
    if (selectedPsychologist) {
      await reassignPsychologist({
        userId: selectedPsychologist.id,
        consultId: consult.id,
      });
      setIsOpen(false);
    }
  };
  return (
    <>
      <Dialog open={isFirstStepOpen} maxWidth="md">
        <DialogHeader
          iconLigature="person_add"
          title={t("ReassignPsychologist.Title")}
          outlined
        />
        <div />

        <PsychologistSelectorTable
          psychologists={allPsychologists}
          setSelectedPsychologist={setSelectedPsychologist}
          selectedPsychologist={selectedPsychologist}
          page={page}
          setPage={setPage}
          total={total}
          isFetching={isFetching}
          hasNextPage={hasNextPage}
          search={search}
          setSearch={setSearch}
        />
        <DialogActionButtons
          onSubmitText={t("SharedStrings.Reassign").toUpperCase()}
          onCancelText={t("SharedStrings.Cancel").toUpperCase()}
          onCancel={() => setIsOpen(false)}
          onSubmit={onSelection}
          submitting={isReassigningPsychologist}
        />
      </Dialog>
      <Dialog open={!isFirstStepOpen} maxWidth="md">
        <p className="pt-4 pb-8 body2">
          {t("ReassignPsychologist.Confirmation", {
            name: `${selectedPsychologist?.firstName} ${selectedPsychologist?.lastName ?? ""}`,
          })}
        </p>
        <DialogActionButtons
          onSubmitText={t("SharedStrings.Confirm")}
          onCancelText={t("SharedStrings.Discard")}
          onCancel={() => setIsFirstStepOpen(true)}
          onSubmit={onSubmit}
        />
      </Dialog>
    </>
  );
};

export default withSnackbar(SwapPsychologistDialog);
