import { ReactSVGElement, SVGProps, type FC } from "react";
import ErrorOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
// eslint-disable-next-line import/no-unresolved
import importFlagSvg from "@svgx-dir:/src/resources/flags";
import { Tooltip } from "@mui/material";

interface IconSessionLanguageProps {
  language: string;
  tooltip?: string;
  className?: string;
}

export const IconSessionLanguage: FC<IconSessionLanguageProps> = ({
  language,
  tooltip,
  className = "",
}) => {
  let LoadedSvg: FC<SVGProps<ReactSVGElement>> | undefined;
  let error;
  try {
    LoadedSvg = importFlagSvg(language);
  } catch (err) {
    error = err;
  }

  if (error) {
    return <ErrorOutlinedIcon />;
  }

  if (!LoadedSvg) {
    return null;
  }

  return (
    <Tooltip title={tooltip} placement="top">
      <div className="w-fit">
        <LoadedSvg className={className} aria-label={language} />
      </div>
    </Tooltip>
  );
};
