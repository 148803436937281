import {
  Button,
  Dialog,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useGoogleLogin } from "@react-oauth/google";
import authenticationService from "@src/services/authentication.service";
import { useCalendarQuery } from "@src/queries/calendar";
import { timeSnackbar } from "@src/constants";
import { DialogActionButtons, DialogHeader } from "./DialogToolkit";
import { withSnackbar } from "./SnackBarComponent";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  formItem: {
    flexBasis: "100%",
    height: 72,
  },
  button: {
    borderRadius: 24,
    padding: theme.spacing(0, 4),
    textTransform: "none",
  },
}));

const RegisterCalendarDialog = ({ open, onClose, ...props }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [calendars, setCalendars] = useState([]);
  const [notRegistered, setNotRegistered] = useState(false);
  const { refetch, calendarData, registerCalendar } = useCalendarQuery();

  useEffect(() => {
    if (calendarData?.notRegistered) {
      setNotRegistered(true);
    } else {
      setCalendars(calendarData);
    }
  }, [calendarData]);

  const onSuccess = async (res) => {
    await authenticationService
      .grantCalendarAccess(res.code, "Google")
      .then((response) => {
        props.snackbarShowMessage(response.message, "success");
        setTimeout(() => {
          setNotRegistered(false);
          refetch();
        }, timeSnackbar);
      })
      .catch((error) => {
        props.snackbarShowMessage(error.response.data, "error");
      });
  };

  const login = useGoogleLogin({
    onSuccess: (response) => onSuccess(response),
    scope: "https://www.googleapis.com/auth/calendar",
    flow: "auth-code",
    onError: (error) => onFailure(error),
  });

  const onFailure = (res) => {
    if (res?.error !== "popup_closed_by_user") {
      props.snackbarShowMessage(res.response.responseText);
    }
  };

  const formik = useFormik({
    initialValues: {
      calendarId: calendars?.length === 1 ? calendars[0].calendarId : "",
    },
    validationSchema: Yup.object({
      calendarId: Yup.string().required(t("RegisterCalendar.Error")),
    }),

    onSubmit: async (values) => {
      try {
        formik.setSubmitting(true);
        registerCalendar(values.calendarId);
        onClose();
      } catch (e) {
        if (e.response.data.errors === undefined) {
          throw e;
        }

        for (const [key, value] of Object.entries(e.response.data.errors)) {
          props.snackbarShowMessage(`${key}: ${value}`);
        }

        formik.setSubmitting(false);
      }
    },
    enableReinitialize: true,
  });

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogHeader
        iconLigature="calendar_today"
        title={t("Calendar.Add")}
        outlined
      />

      {notRegistered && (
        <Button
          className={classes.button}
          variant="contained"
          color="secondary"
          onClick={() => login()}
        />
      )}

      {calendars?.length > 0 && (
        <form
          onSubmit={formik.handleSubmit}
          className={classes.form}
          noValidate
        >
          <FormControl variant="standard" className={classes.formItem} required>
            <InputLabel id="calendarId-label">
              {t("Common.Calendar")}
            </InputLabel>
            <Select
              variant="standard"
              name="calendarId"
              labelId="calendarId-label"
              id="calendarId"
              error={!!formik.errors.calendarId}
              onChange={formik.handleChange}
              value={formik.values.calendarId}
            >
              {calendars?.map((calendar) => (
                <MenuItem key={calendar.calendarId} value={calendar.calendarId}>
                  {calendar.summary}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {formik.errors.calendarId && (
            <span className="error">{formik.errors.calendarId}</span>
          )}
        </form>
      )}
      <DialogActionButtons
        onCancel={() => onClose()}
        onSubmit={formik.submitForm}
      />
    </Dialog>
  );
};

export default withSnackbar(RegisterCalendarDialog);
