import { createTheme, Icon } from "@mui/material";
import { alpha } from "@mui/material/styles";
import { red } from "@mui/material/colors";
import typography from "./typography";
import { colors } from "./colors";

export { colors } from "./colors";

const theme = createTheme({
  palette: {
    background: {
      dark: "#F4F6F8",
      default: "#F4F6F8",
      paper: "#F4F6F8",
    },
    primary: {
      main: colors.primaryDarkBlue,
    },
    secondary: {
      main: colors.primaryBlue,
      contrastText: "#fff",
    },
    error: {
      main: red[500],
    },
    text: {
      primary: colors.primaryDarkBlue,
      secondary: colors.primaryDarkBlue,
    },
  },
  // shadows,
  colors,
  typography,
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          lineHeight: "24px !important",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: "16px 0px",
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          "&$hover:hover": {
            backgroundColor: alpha(colors.primaryBlue, 0.1),
            cursor: "pointer",
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          minWidth: "500px",
          borderRadius: 8,
          padding: "24px 24px 0 24px",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          "&:disabled": {
            backgroundColor: alpha(colors.primaryBlue, 0.5),
          },
        },

        contained: {
          borderRadius: 24,
        },
        outlined: {
          border: `2px solid ${colors.primaryDarkBlue}`,
          padding: "12px 24px",
          backgroundColor: "white",
          borderRadius: 30,
          color: colors.primaryDarkBlue,
          "&:hover": {
            backgroundColor: "white",
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: "white",
        },
        elevation8: {
          boxShadow: "none",
        },
        rounded: {
          borderRadius: "16px",
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        message: {
          "& a": { color: "inherit" },
        },
      },
    },
  },
  props: {
    MuiGrid: {
      styleOverrides: {
        spacing: 4,
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        separator: <Icon>chevron_left</Icon>,
      },
    },
    MuiPaper: {
      styleOverrides: {
        elevation: 4,
      },
    },
  },
});

export default theme;
