import { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from "@mui/material";
import { ConsultFeedback } from "@src/models";
import { useNavigate } from "react-router";
import { isEmptyQueryResult } from "@src/queries/utils";
import NothingFound from "@src/components/NothingFound";
import {
  formatFirstUpperCase,
  formatStandardDate,
} from "@src/utils/formatting";
import { ConsultReviewQuestionsKeys } from "@src/constants";
import { useExpertFeedbackQuery } from "@src/queries/experts";
import { useTranslation } from "react-i18next";
import { ThumbsUpDownOutlined } from "@mui/icons-material";
import { DateFilter } from "@src/models/DateFilter";
import DateFilterDropdown from "@src/components/DateFilterDropdown";

interface MyFeedbacksViewProps {}

const MyFeedbacksView: React.FC<MyFeedbacksViewProps> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [page, setPage] = useState(0);
  const [dateFilter, setDateFilter] = useState<DateFilter>(DateFilter.AllTime);

  // Fetch feedbacks using the custom hook
  const { data: feedbacks, isFetching } = useExpertFeedbackQuery(
    dateFilter.valueOf(),
    page,
  );
  const [expandedRowConsultId, setExpandedRowConsultId] = useState<number>();

  const formatTooltip = (key: string) => t(key).replace(" <0>*</0>", "");

  const dateFilterItems = Object.values(DateFilter);

  const columns: {
    id: string;
    minWidth: number;
    tooltip?: string;
  }[] = [
    {
      id: "MyFeedbacks.DateTime",
      minWidth: 80,
    },
    {
      id: "MyFeedbacks.Client",
      minWidth: 250,
    },
    {
      id: "MyFeedbacks.SessionType",
      minWidth: 100,
    },
    {
      id: "MyFeedbacks.Q1",
      minWidth: 50,
      tooltip: formatTooltip(t("Consult.Review.Question1")),
    },
    {
      id: "MyFeedbacks.Q2",
      minWidth: 50,
      tooltip: formatTooltip(t("Consult.Review.Question2")),
    },
    {
      id: "MyFeedbacks.Q3",
      minWidth: 50,
      tooltip: formatTooltip(t("Consult.Review.Question3")),
    },
    {
      id: "MyFeedbacks.Q4",
      minWidth: 50,
      tooltip: t("Consult.Review.Feedback"),
    },
  ];

  const handleRowClick = (event, consultFeedback: ConsultFeedback) => {
    event.stopPropagation();
    if (consultFeedback) {
      navigate(`/clients/${consultFeedback.clientId}`);
    }
  };

  function handleFeedbackClick(
    event: React.MouseEvent<HTMLSpanElement>,
    consultFeedback: ConsultFeedback,
  ): void {
    event.stopPropagation();
    if (expandedRowConsultId === consultFeedback.consultId) {
      setExpandedRowConsultId(undefined);
    } else {
      setExpandedRowConsultId(consultFeedback.consultId);
    }
  }

  const consultTypeCell = (consultFeedback: ConsultFeedback) => {
    return (
      <TableCell>
        <span className="py-2 sub1">
          {formatFirstUpperCase(consultFeedback.consultType)}
        </span>
      </TableCell>
    );
  };

  const consultDateCell = (consultFeedback: ConsultFeedback) => {
    return (
      <TableCell>
        <span className="py-2 sub1">
          {formatStandardDate(consultFeedback.startedAt, "Europe/Amsterdam")}
        </span>
      </TableCell>
    );
  };

  const consultClientCell = (consultFeedback: ConsultFeedback) => {
    return (
      <TableCell>
        <span className="py-2 sub1 hover:text-blue-600">
          {consultFeedback.clientEmail}
        </span>
      </TableCell>
    );
  };

  const consultQ1Cell = (consultFeedback: ConsultFeedback) => {
    return (
      <TableCell>
        <span className="py-2 sub1">
          {`${consultFeedback.answers.find((answer) => answer.questionKey === ConsultReviewQuestionsKeys.Question1)?.value ?? ""}`}
        </span>
      </TableCell>
    );
  };

  const consultQ2Cell = (consultFeedback: ConsultFeedback) => {
    return (
      <TableCell>
        <span className="py-2 sub1">
          {`${consultFeedback.answers.find((answer) => answer.questionKey === ConsultReviewQuestionsKeys.Question2)?.value ?? ""}`}
        </span>
      </TableCell>
    );
  };

  const consultQ3Cell = (consultFeedback: ConsultFeedback) => {
    return (
      <TableCell>
        <span className="py-2 sub1">
          {`${consultFeedback.answers.find((answer) => answer.questionKey === ConsultReviewQuestionsKeys.Question3)?.value ?? ""}`}
        </span>
      </TableCell>
    );
  };

  const consultFeedbackCell = (consultFeedback: ConsultFeedback) => {
    const consultFeedbackValue =
      consultFeedback.answers.find(
        (answer) => answer.questionKey === ConsultReviewQuestionsKeys.Question4,
      )?.value ?? "";

    return (
      <TableCell
        onMouseUp={(event) => event.stopPropagation()}
        onClick={(event) => event.stopPropagation()}
      >
        <span
          key={consultFeedback.consultId}
          data-testid={`consult-feedback-text-${consultFeedback.consultId}`}
          className={
            consultFeedback.consultId === expandedRowConsultId
              ? "py-2 sub1" // expanded
              : "py-2 line-clamp-2 sub1 max-h-14"
          }
          role="button"
          onClick={(event) => handleFeedbackClick(event, consultFeedback)}
        >{`${consultFeedbackValue}`}</span>
      </TableCell>
    );
  };

  const handleFilterSelect = (filter: DateFilter) => {
    setDateFilter(filter);
  };

  useEffect(() => {}, [expandedRowConsultId]);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <DateFilterDropdown
          items={dateFilterItems}
          onSelectDateFilter={handleFilterSelect}
        />
      </Grid>
      {isEmptyQueryResult(feedbacks) ? (
        <NothingFound translationKey="MyFeedbacks.List.NotFound" />
      ) : (
        <Grid item xs={12}>
          <Paper className="px-3 py-4 rounded-md">
            <Box display="flex" alignItems="center">
              <ThumbsUpDownOutlined className="mr-2" />
              <h5 className="h5">{t("MyFeedbacks.Title")}</h5>
              {!!feedbacks?.pagination.total && (
                <TablePagination
                  component="div"
                  className="ml-auto -mr-2"
                  count={feedbacks.pagination.total}
                  rowsPerPage={feedbacks.pagination.limit}
                  rowsPerPageOptions={[10]}
                  page={feedbacks.pagination.currentPage}
                  onPageChange={(_, newPage) => setPage(newPage)}
                  slotProps={{
                    actions: {
                      nextButton: {
                        disabled:
                          isFetching || !feedbacks.pagination.hasNextPage,
                      },
                      previousButton: {
                        disabled:
                          isFetching || feedbacks.pagination.currentPage === 0,
                      },
                    },
                  }}
                />
              )}
            </Box>
            {!isFetching && !!feedbacks?.pagination.total && (
              <TableContainer>
                <Table
                  data-testid="feedbacks"
                  stickyHeader
                  aria-label="sticky table"
                >
                  <TableHead>
                    <TableRow>
                      {columns.map((column) =>
                        column.tooltip ? (
                          <Tooltip
                            title={column.tooltip}
                            key={column.id}
                            placement="top"
                          >
                            <TableCell
                              key={column.id}
                              style={{ minWidth: column.minWidth }}
                              className="pl-1 py-2 sub1"
                            >
                              {t(column.id)}
                            </TableCell>
                          </Tooltip>
                        ) : (
                          <TableCell
                            key={column.id}
                            style={{ minWidth: column.minWidth }}
                            className="pl-1 py-2 sub1"
                          >
                            {t(column.id)}
                          </TableCell>
                        ),
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {feedbacks.data.map((consultFeedback: ConsultFeedback) => (
                      <TableRow
                        data-testId="feedbacks-row"
                        hover
                        role="checkbox"
                        className="opacity-70 cursor-pointer"
                        tabIndex={-1}
                        key={consultFeedback.consultId.toString()}
                        id={consultFeedback.consultId.toString()}
                        onClick={(event) =>
                          handleRowClick(event, consultFeedback)
                        }
                      >
                        {consultDateCell(consultFeedback)}
                        {consultClientCell(consultFeedback)}
                        {consultTypeCell(consultFeedback)}
                        {consultQ1Cell(consultFeedback)}
                        {consultQ2Cell(consultFeedback)}
                        {consultQ3Cell(consultFeedback)}
                        {consultFeedbackCell(consultFeedback)}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Paper>
        </Grid>
      )}
    </Grid>
  );
};

export default MyFeedbacksView;
