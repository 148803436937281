import { useState } from "react";
import { Dialog } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import PsychologistSelectorTable from "@src/components/PsychologistSelectorTable";
import { usePsychologistsQuery } from "@src/queries/experts";
import { Psychologist } from "@src/models/Psychologist";
import { smallTablePageSize } from "@src/constants";
import { withSnackbar, WithSnackbarProps } from "./SnackBarComponent";
import { DialogActionButtons, DialogHeader } from "./DialogToolkit";

interface PsychologistSelectorDialogProps extends WithSnackbarProps {
  setIsOpen: (open: boolean) => void;
  clientId: string;
}

const PsychologistSelectorDialog: React.FC<PsychologistSelectorDialogProps> = ({
  setIsOpen,
  clientId,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [selectedPsychologist, setSelectedPsychologist] =
    useState<Psychologist | null>(null);
  const [search, setSearch] = useState<string>("");
  const [page, setPage] = useState(0);

  const {
    data: {
      data: allPsychologists = [],
      pagination: { total = 0, hasNextPage = false } = {},
    } = {},
    isFetching,
  } = usePsychologistsQuery({ page, limit: smallTablePageSize, search });

  const onSelection = async () => {
    if (selectedPsychologist) {
      navigate(`/book-session/${clientId}/${selectedPsychologist.id}`);
      setIsOpen(false);
    }
  };

  return (
    <Dialog
      open
      classes={{ paper: "max-h-[655px]" }}
      data-cy="psychologist-selector"
      maxWidth="md"
    >
      <DialogHeader
        iconLigature="psychology"
        title={t("PsychologistSelection.Title")}
        outlined
      />

      <PsychologistSelectorTable
        psychologists={allPsychologists}
        setSelectedPsychologist={setSelectedPsychologist}
        selectedPsychologist={selectedPsychologist}
        page={page}
        setPage={setPage}
        total={total}
        isFetching={isFetching}
        hasNextPage={hasNextPage}
        search={search}
        setSearch={setSearch}
      />

      <DialogActionButtons
        onSubmitText={t("PsychologistSelection.Select").toUpperCase()}
        onCancelText={t("PsychologistSelection.Cancel").toUpperCase()}
        onCancel={() => setIsOpen(false)}
        onSubmit={onSelection}
      />
    </Dialog>
  );
};

export default withSnackbar(PsychologistSelectorDialog);
