import { Slide, Snackbar, Alert } from "@mui/material";
import { isValidElement, ReactElement, useState } from "react";

type SeverityType = "success" | "info" | "warning" | "error";
export const withSnackbar = (WrappedComponent) => {
  return (props) => {
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState<string | ReactElement>("");
    const [duration, setDuration] = useState(2000);
    const [severity, setSeverity] = useState<SeverityType>("error");

    const showMessage = (
      msg: string | ReactElement,
      sev: SeverityType = "error",
      dur: number = 2000,
    ) => {
      setMessage(
        typeof msg === "object" && !isValidElement(msg)
          ? JSON.stringify(msg)
          : msg,
      );
      setSeverity(sev);
      setDuration(dur);
      setOpen(true);
    };

    const handleClose = (
      _event?: React.SyntheticEvent | Event,
      reason?: string,
    ) => {
      if (reason === "clickaway") {
        return;
      }
      setOpen(false);
    };
    return (
      <>
        <WrappedComponent {...props} snackbarShowMessage={showMessage} />
        <Snackbar
          id="toaster"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          autoHideDuration={duration}
          open={open}
          onClose={handleClose}
          TransitionComponent={Slide}
        >
          <Alert variant="filled" onClose={handleClose} severity={severity}>
            {message}
          </Alert>
        </Snackbar>
      </>
    );
  };
};
export interface WithSnackbarProps {
  snackbarShowMessage: (
    message: string,
    severity?: SeverityType,
    duration?: number,
  ) => void;
}
