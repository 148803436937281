import {
  Box,
  Grid,
  Icon,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from "@mui/material";
import moment from "moment";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useDebouncedCallback } from "use-debounce";
import ChangeSessionStatusDialog from "@src/components/ChangeSessionStatusDialog/ChangeSessionStatusDialog";
import NothingFound from "@src/components/NothingFound";
import PageTitle from "@openup/shared/components/PageTitle/PageTitle";
import SearchBox from "@src/components/SearchBox";
import SwapPsychologistDialog from "@src/components/SwapPsychologistDialog";
import { isEmptyQueryResult } from "@src/queries/utils";
import { pageSize, STATUS, STATUS_SUBJECT_FOR_CHANGE } from "@src/constants";
import { formatFirstUpperCase } from "@src/utils/formatting";
import Filters from "@openup/shared/components/Filters/Filters";
import Spinner from "@openup/shared/components/Spinner/Spinner";
import {
  WithSnackbarProps,
  withSnackbar,
} from "@src/components/SnackBarComponent";
import { InfoOutlined } from "@mui/icons-material";

interface SessionsViewProps extends WithSnackbarProps {
  titleText: string;
  placeholderSearch: string;
  page: number;
  statusFilter?: string;
  setPage: (page: number) => void;
  setSearch: (search: string) => void;
  setStatusFilter?: (status: string) => void;
  columns: {
    id: string;
    minWidth: number;
  }[];
  isFetching: boolean;
  consults: any[];
  total: number;
  hasNextPage: boolean;
  showStatusFilter?: boolean;
  showReassign?: boolean;
  showPsychologistName?: boolean;
  showJoinSession?: boolean;
  showRescheduleSession?: boolean;
  showCancelSession?: boolean;
  showSessionDetails?: boolean;
  showChangeSessionStatus?: boolean;
  showCopyJoinLink: boolean;
  showReferralToolkitIcon: boolean;
}
const SessionsView: FC<SessionsViewProps> = ({
  titleText,
  placeholderSearch,
  page,
  statusFilter = "",
  setPage,
  setSearch,
  setStatusFilter = () => {},
  columns,
  isFetching,
  consults,
  total,
  hasNextPage,
  showStatusFilter = false,
  showReassign = false,
  showPsychologistName = false,
  showJoinSession = true,
  showRescheduleSession = true,
  showCancelSession = true,
  showSessionDetails = true,
  showChangeSessionStatus = false,
  showCopyJoinLink = false,
  showReferralToolkitIcon = false,
  snackbarShowMessage,
}) => {
  const buttonIconClassName =
    "w-8 h-8 p-1 flex items-center mr-2 text-2xl rounded bg-indigo-800 text-white hover:bg-blue-500 hover:opacity-70";
  const buttonIconDisabledClassName =
    " bg-slate-300 hover:bg-slate-300 hover:opacity-100 cursor-not-allowed";

  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isSwapPsychologistOpen, setIsSwapPsychologistOpen] = useState(false);
  const [changeStatusOpen, setChangeStatusOpen] = useState(false);
  const [selectedConsult, setSelectedConsult] = useState<any>(null);
  const isInternallyScheduled = (consult: any) => !!consult.psychologistUrl;

  const onSearchChange = useDebouncedCallback((searchValue) => {
    setSearch(searchValue);
    setPage(0);
  }, 500);

  const handleClickSwapSession = (event: React.MouseEvent, consult: any) => {
    event.stopPropagation();
    setSelectedConsult(consult);
    setIsSwapPsychologistOpen(true);
  };
  const handleClickChangeSessionStatus = (
    event: React.MouseEvent,
    consult: any,
  ) => {
    event.stopPropagation();
    if (STATUS_SUBJECT_FOR_CHANGE.includes(consult.status)) {
      setSelectedConsult(consult);
      setChangeStatusOpen(true);
    }
  };

  const handleClickStartSession = (event: React.MouseEvent, consult: any) => {
    event.stopPropagation();
    if (isInternallyScheduled(consult)) {
      window.open(consult.psychologistUrl, "_blank", "noopener");
    }
  };

  const handleClickRescheduleSession = (
    event: React.MouseEvent,
    consult: any,
  ) => {
    event.stopPropagation();
    if (isInternallyScheduled(consult)) {
      navigate(`/my-sessions/consult/${consult.id}/time-picker`);
    }
  };

  const handleClickCancelSession = (event: React.MouseEvent, consult: any) => {
    event.stopPropagation();
    if (isInternallyScheduled(consult)) {
      navigate(`/my-sessions/consult/${consult.id}/cancel-session`);
    }
  };

  const handleClickSessionRow = (event: React.MouseEvent, consult: any) => {
    event.stopPropagation();
    if (showSessionDetails) {
      navigate(`/clients/${consult.user.id}`);
    }
  };

  const handleClickCopyJoinLink = async (
    event: React.MouseEvent,
    consult: any,
  ) => {
    event.stopPropagation();

    await navigator.clipboard.writeText(consult.guestUrl);
    snackbarShowMessage(t("SessionsView.LinkCopiedClipboard"), "success");
  };
  const statusFilters = [
    {
      id: STATUS.SCHEDULED,
      name: t("Sessions.StatusFilter.Scheduled"),
      icon: "",
    },
    {
      id: STATUS.COMPLETED,
      name: t("Sessions.StatusFilter.Completed"),
      icon: "",
    },
    { id: "", name: t("Sessions.StatusFilter.All"), icon: "" },
  ];
  return (
    <>
      <PageTitle applicationName={t("Navigation.Title")} title={titleText} />
      {isSwapPsychologistOpen && (
        <SwapPsychologistDialog
          setIsOpen={setIsSwapPsychologistOpen}
          consult={selectedConsult}
        />
      )}
      {selectedConsult && (
        <ChangeSessionStatusDialog
          open={changeStatusOpen}
          setOpen={setChangeStatusOpen}
          consult={selectedConsult}
        />
      )}
      <Grid container spacing={4}>
        {showStatusFilter && (
          <Grid item xs={12} className="flex">
            <Filters
              selectedId={statusFilter}
              list={statusFilters}
              onClick={setStatusFilter}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <SearchBox
            onChange={onSearchChange}
            placeholder={placeholderSearch}
            className="flex-grow"
          />
        </Grid>

        {isEmptyQueryResult(consults) ? (
          <NothingFound translationKey="Sessions.List.NotFound" />
        ) : (
          <Grid item xs={12}>
            <Paper className="px-3 py-4 rounded-md">
              <Box display="flex" alignItems="center">
                <Icon className="mr-2">fact_check_outlined</Icon>
                <h5 className="h5">{titleText}</h5>
                <TablePagination
                  component="div"
                  className="ml-auto -mr-2"
                  count={total}
                  rowsPerPage={pageSize}
                  rowsPerPageOptions={[10]}
                  page={page}
                  onPageChange={(_, newPage) => setPage(newPage)}
                  slotProps={{
                    actions: {
                      nextButton: { disabled: isFetching || !hasNextPage },
                      previousButton: { disabled: isFetching || page === 0 },
                    },
                  }}
                />
              </Box>
              {!isFetching && (
                <TableContainer>
                  <Table
                    stickyHeader
                    aria-label="sticky table"
                    data-cy="all-sessions-table"
                  >
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            style={{ minWidth: column.minWidth }}
                            className="pl-1 py-2 sub1"
                          >
                            {t(column.id)}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {consults.map((consult) => (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={consult.id}
                          className="opacity-70 cursor-pointer"
                          onClick={(event) =>
                            handleClickSessionRow(event, consult)
                          }
                        >
                          <TableCell className="py-2 sub1 hover:text-blue-600">
                            {moment(consult.startedAt)
                              .tz("Europe/Amsterdam")
                              .format("DD-MM-YY HH:mm")}
                          </TableCell>
                          {showReferralToolkitIcon && (
                            <TableCell className="py-2 sub1 hover:text-blue-600">
                              {consult.employerOfClientHasReferralToolkit && (
                                <Tooltip
                                  title={t(
                                    "MySessions.EmployerReferralToolkitInfo.Tooltip",
                                  )}
                                  placement="top"
                                  data-cy="employer-referral-toolkit-info-column"
                                >
                                  <InfoOutlined data-cy="employer-referral-toolkit-info-icon" />
                                </Tooltip>
                              )}
                            </TableCell>
                          )}
                          <TableCell className="py-2 sub1 underline hover:text-blue-600">
                            {`${consult.user.firstName} ${
                              consult.user.lastName ?? ""
                            } `}
                            {consult.user.email}
                          </TableCell>
                          <TableCell className="py-2 sub1 hover:text-blue-600">
                            {consult.consultType
                              ? formatFirstUpperCase(consult.consultType)
                              : ""}
                          </TableCell>
                          <TableCell className="py-2 sub1 hover:text-blue-600">
                            {consult.consultStage}
                          </TableCell>
                          {showPsychologistName && (
                            <TableCell
                              className="py-2 sub1 hover:text-blue-600"
                              data-cy="psychologist-column"
                            >
                              {`${consult.employee.firstName} ${consult.employee.lastName}`}
                            </TableCell>
                          )}
                          {showReassign && (
                            <TableCell className="py-2">
                              <Tooltip
                                title={t("ReassignPsychologist.Title")}
                                placement="top"
                                data-cy="reassign-session-button"
                              >
                                <Icon
                                  className={buttonIconClassName}
                                  onClick={(event) =>
                                    handleClickSwapSession(event, consult)
                                  }
                                >
                                  social_distance
                                </Icon>
                              </Tooltip>
                            </TableCell>
                          )}
                          <TableCell className="py-2 sub1">
                            <Box display="flex" alignItems="center">
                              <span className="mr-2 hover:text-blue-600">
                                {t(
                                  `Consults.Status.${formatFirstUpperCase(
                                    consult.status,
                                  )}`,
                                )}
                              </span>
                              {consult.status.toLowerCase() ===
                                STATUS.SCHEDULED.toLowerCase() && (
                                <>
                                  {showJoinSession && (
                                    <Tooltip
                                      title={t("Common.JoinSession")}
                                      placement="top"
                                      data-cy="join-session-button"
                                    >
                                      <Icon
                                        className={
                                          buttonIconClassName +
                                          (isInternallyScheduled(consult)
                                            ? ""
                                            : buttonIconDisabledClassName)
                                        }
                                        onClick={(event) =>
                                          handleClickStartSession(
                                            event,
                                            consult,
                                          )
                                        }
                                      >
                                        play_circle_outlined
                                      </Icon>
                                    </Tooltip>
                                  )}
                                  {showCopyJoinLink && (
                                    <Tooltip
                                      title={t("SessionsView.CopyJoinLink")}
                                      placement="top"
                                      data-cy="copy-link-button"
                                    >
                                      <Icon
                                        className={buttonIconClassName}
                                        onClick={(event) =>
                                          handleClickCopyJoinLink(
                                            event,
                                            consult,
                                          )
                                        }
                                      >
                                        link_outlined
                                      </Icon>
                                    </Tooltip>
                                  )}
                                  {showRescheduleSession && (
                                    <Tooltip
                                      title={t("Common.RescheduleSession")}
                                      placement="top"
                                      data-cy="reschedule-session-button"
                                    >
                                      <Icon
                                        className={
                                          buttonIconClassName +
                                          (isInternallyScheduled(consult)
                                            ? ""
                                            : buttonIconDisabledClassName)
                                        }
                                        onClick={(event) =>
                                          handleClickRescheduleSession(
                                            event,
                                            consult,
                                          )
                                        }
                                      >
                                        update_outlined
                                      </Icon>
                                    </Tooltip>
                                  )}
                                  {showCancelSession && (
                                    <Tooltip
                                      title={t("Common.CancelSession")}
                                      placement="top"
                                      data-cy="cancel-session-button"
                                    >
                                      <Icon
                                        className={
                                          buttonIconClassName +
                                          (isInternallyScheduled(consult)
                                            ? ""
                                            : buttonIconDisabledClassName)
                                        }
                                        onClick={(event) =>
                                          handleClickCancelSession(
                                            event,
                                            consult,
                                          )
                                        }
                                      >
                                        highlight_off_outlined
                                      </Icon>
                                    </Tooltip>
                                  )}
                                </>
                              )}
                              {showChangeSessionStatus && (
                                <Tooltip
                                  title={t("AllSessions.ChangeStatus")}
                                  placement="top"
                                  data-cy="change-status-button"
                                  className={
                                    buttonIconClassName +
                                    (STATUS_SUBJECT_FOR_CHANGE.includes(
                                      consult.status,
                                    )
                                      ? ""
                                      : buttonIconDisabledClassName)
                                  }
                                >
                                  <Icon
                                    className={buttonIconClassName}
                                    onClick={(event) =>
                                      handleClickChangeSessionStatus(
                                        event,
                                        consult,
                                      )
                                    }
                                  >
                                    sync_alt_outlined
                                  </Icon>
                                </Tooltip>
                              )}
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
              {isFetching && (
                <div className="flex w-full justify-center">
                  <Spinner />
                </div>
              )}
            </Paper>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default withSnackbar(SessionsView);
