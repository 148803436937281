import { InputBase } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { ChangeEvent, ForwardedRef } from "react";

interface SearchBoxProps {
  onChange: (value: string) => void;
  placeholder?: string;
  className?: string;
  refCancel?: ForwardedRef<HTMLInputElement>;
}

const SearchBox: React.FC<SearchBoxProps> = ({
  onChange,
  placeholder = "Search...",
  className = "",
  refCancel,
}) => {
  return (
    <div className={`relative rounded-lg bg-white h-12 ${className}`}>
      <div className="absolute left-0 pl-2 h-full flex items-center pointer-events-none">
        <SearchIcon />
      </div>
      <InputBase
        name="search-bar"
        placeholder={placeholder}
        classes={{
          root: "text-inherit w-full h-12",
          input: "py-1 pr-1 pl-10 w-full transition-width",
        }}
        inputProps={{ "aria-label": "search" }}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          onChange(e.target.value)
        }
        inputRef={refCancel}
      />
    </div>
  );
};

export default SearchBox;
