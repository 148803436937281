import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { DateFilter } from "@src/models/DateFilter";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

interface DateFilterDropdownProps {
  items: DateFilter[];
  onSelectDateFilter: (filter: DateFilter) => void;
}

const DateFilterDropdown: React.FC<DateFilterDropdownProps> = ({
  items,
  onSelectDateFilter,
}) => {
  const [selectedDateFilter, setSelectedDateFilter] = useState<DateFilter>(
    DateFilter.AllTime,
  );

  const { t } = useTranslation();
  const onSelectChange = (e: SelectChangeEvent<string>) => {
    const filter = e.target.value as DateFilter;
    setSelectedDateFilter(filter);
    onSelectDateFilter(filter);
  };

  return (
    <FormControl
      variant="standard"
      className="w-[18vw] bg-orange-100 print:hidden"
    >
      <Select
        name="dateFilter"
        labelId="dateFilter-label"
        id="dateFilter-select"
        data-testid="dateFilter-select"
        className="mb-4 .hover:bg-opacity-10 [&>fieldset]:border-none [&>.MuiSvgIcon-root]:text-indigo-800"
        onChange={onSelectChange}
        value={selectedDateFilter.valueOf()}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          className: "[&>.MuiPaper-root]:shadow-lg [&>.MuiPaper-root]:p-0",
        }}
      >
        {items.map((dateFilterItem) => (
          <MenuItem key={dateFilterItem} value={dateFilterItem.valueOf()}>
            <p className="cap1 text-sm">{t(`DateFilter.${dateFilterItem}`)}</p>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default DateFilterDropdown;
