import { useTranslation } from "react-i18next";
import { TAG_TYPES } from "@src/constants";
import { getConsultType } from "@src/utils/helpers";
import SessionTypeCard from "@openup/shared/components/Cards/SessionTypeCard/SessionTypeCard";
import { ArrowForward } from "@mui/icons-material";
import { ReactComponent as ManMeditation } from "@src/resources/images/man-fitness.svg";
import { ReactComponent as WomanLaptopStanding } from "@src/resources/images/woman-laptop-standing.svg";
import { ReactComponent as WomanCelebrating } from "@src/resources/images/woman-celebrating.svg";
import { Consult, ExpertTag } from "@src/models";
import BookingContainer from "./BookingContainer";

interface SessionTypeProps {
  moveToNextStep: () => void;
  setSessionType: (sessionType: string) => void;
  psychologistTags: ExpertTag[];
  consult: Consult;
  clientFirstName?: string;
}

const SESSION_TYPE = {
  GENERAL: "general",
  MINDFULNESS: "mindfulness",
  PHYSICAL_WELLBEING: "physical-wellbeing",
};

const SessionType: React.FC<SessionTypeProps> = ({
  moveToNextStep,
  setSessionType,
  psychologistTags,
  consult,
  clientFirstName,
}) => {
  const { t } = useTranslation();

  const handlePressCard = (sessionType: string) => {
    setSessionType(sessionType);
    moveToNextStep();
  };

  const hasSessionType = (sessionType: string) => {
    const sessionTags = psychologistTags.filter(
      (tag) =>
        tag.tagType.toLowerCase() === TAG_TYPES.SESSION_TYPE.toLowerCase(),
    );
    return (
      sessionTags.some(
        (tag) => tag.tagName.toLowerCase() === sessionType.toLowerCase(),
      ) || getConsultType(consult) === sessionType.toLowerCase()
    );
  };

  const title = clientFirstName
    ? t("Booking.ForClient.SessionType.Title", { firstname: clientFirstName })
    : t("Booking.SessionType.Title");

  return (
    <BookingContainer title={title} isMediumContainerWanted>
      <div className="flex flex-wrap w-full mx-auto justify-center items-center gap-8 md:gap-6 pb-6">
        {hasSessionType(SESSION_TYPE.GENERAL) && (
          <SessionTypeCard
            onClick={() => handlePressCard(SESSION_TYPE.GENERAL)}
            cardTitle={t("Booking.SessionType.General.Description")}
            cardSubtitle={t("Booking.SessionType.General.Details")}
            icon={<ArrowForward className="text-blue-500" />}
            image={<WomanLaptopStanding />}
          />
        )}
        {hasSessionType(SESSION_TYPE.MINDFULNESS) && (
          <SessionTypeCard
            onClick={() => handlePressCard(SESSION_TYPE.MINDFULNESS)}
            cardTitle={t("Booking.SessionType.Mindfulness.Description")}
            cardSubtitle={t("Booking.SessionType.Mindfulness.Details")}
            icon={<ArrowForward className="text-blue-500" />}
            image={<ManMeditation />}
          />
        )}
        {hasSessionType(SESSION_TYPE.PHYSICAL_WELLBEING) && (
          <SessionTypeCard
            onClick={() => handlePressCard(SESSION_TYPE.PHYSICAL_WELLBEING)}
            cardTitle={t("Booking.SessionType.PhysicalWellbeing.Description")}
            cardSubtitle={t("Booking.SessionType.PhysicalWellbeing.Details")}
            icon={<ArrowForward className="text-blue-500" />}
            image={<WomanCelebrating />}
          />
        )}
      </div>
    </BookingContainer>
  );
};

export default SessionType;
