import { PureComponent } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import authenticationService from "@src/services/authentication.service";
import { timeSnackbar } from "@src/constants";
import { withSnackbar } from "@src/components/SnackBarComponent";

class AuthorizedExternalView extends PureComponent {
  componentDidMount() {
    const { navigate } = this.props;

    authenticationService
      .getExternalUser()
      .then(() => {
        this.props.snackbarShowMessage("Success", "success");
        setTimeout(() => {
          navigate("/login");
        }, timeSnackbar);
      })
      .catch((error) => {
        props.snackbarShowMessage(error.response.data, "error");
      });
  }

  render() {
    return null;
  }
}

AuthorizedExternalView.propTypes = {
  navigate: PropTypes.func.isRequired,
};
const WrappedAuthorizedExternalView = (props) => {
  const navigate = useNavigate();

  return <AuthorizedExternalView {...props} navigate={navigate} />;
};

export default withSnackbar(WrappedAuthorizedExternalView);
