import { Divider } from "@mui/material";
import Button from "@openup/shared/components/Button/Button";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/styles";
import { FC } from "react";
import { TypographyWithIcon } from "./utils/TypographyWithIcon";

interface DialogHeaderProps {
  iconLigature: string;
  title: string;
  outlined?: boolean;
  onDelete?: () => void;
  onFilter?: () => void;
  textRightButton?: string;
}

export const DialogHeader: React.FC<DialogHeaderProps> = ({
  iconLigature,
  title,
  outlined = false,
  onDelete,
  onFilter,
  textRightButton,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const getRightButtonProps = () => {
    if (onDelete) {
      return {
        startIcon: <DeleteOutline style={{ marginTop: -4 }} />,
        onClick: onDelete,
        variant: "contained",
        style: {
          backgroundColor: theme.palette.error.main,
          fontSize: "unset",
          lineHeight: "unset",
          textTransform: "uppercase",
          fontWeight: "bold",
          borderRadius: 4,
          padding: theme.spacing(1, 2),
        },
      };
    }
    if (onFilter) {
      return {
        startIcon: <DeleteOutline />,
        onClick: onFilter,
        variant: "subtitle2",
        style: {
          color: theme.palette.primary.main,
          fontSize: "unset",
          lineHeight: "unset",
        },
      };
    }
    return undefined;
  };

  return (
    <>
      <TypographyWithIcon
        variant="h5"
        className="mb-0 leading-none flex items-center"
        iconLigature={iconLigature}
        outlined={outlined}
        rightButtonText={textRightButton ?? t("Common.Delete")}
        rightButtonProps={getRightButtonProps()}
      >
        {title}
      </TypographyWithIcon>
      <Divider className="mb-3 -mx-3" />
    </>
  );
};

interface DialogActionButtonsProps {
  onCancel?: () => void;
  onCancelText?: string;
  onSubmit: () => void;
  onSubmitText?: string;
  disabled?: boolean;
  submitting?: boolean;
}

export const DialogActionButtons: FC<DialogActionButtonsProps> = ({
  onCancel,
  onCancelText,
  onSubmit,
  onSubmitText,
  disabled = false,
  submitting = false,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Divider className="-mx-3" />
      <div className="my-2 flex basis-full justify-between">
        {onCancel && (
          <Button disabled={disabled} onClick={onCancel} variant="tertiary">
            {onCancelText ?? t("Common.Cancel")}
          </Button>
        )}
        <Button
          variant="primary"
          type="submit"
          onClick={onSubmit}
          disabled={disabled}
          data-cy="submit-button"
          loading={submitting}
        >
          {onSubmitText ?? t("Common.Submit")}
        </Button>
      </div>
    </>
  );
};
