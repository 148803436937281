import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  TablePagination,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { smallTablePageSize, STATUS_TYPE, TAG_TYPES } from "@src/constants";
import { Psychologist } from "@src/models/Psychologist";
import { ExpertTag } from "@src/models/ExpertTag";
import Spinner from "@openup/shared/components/Spinner/Spinner";
import {
  PersonAddOutlined,
  PersonOffOutlined,
  PersonRemoveOutlined,
} from "@mui/icons-material";
import SearchBox from "@src/components/SearchBox";
import { useDebouncedCallback } from "use-debounce";
import { useRef } from "react";
import { withSnackbar, WithSnackbarProps } from "./SnackBarComponent";

interface PsychologistSelectorTableProps extends WithSnackbarProps {
  psychologists: Psychologist[];
  page: number;
  statusFilter?: string;
  setPage: (page: number) => void;
  isFetching: boolean;
  total: number;
  hasNextPage: boolean;
  selectedPsychologist: Psychologist | null;
  setSelectedPsychologist: (psychologist: Psychologist) => void;
  setSearch: (search: string) => void;
}

const columns = [
  { id: "", minWidth: 30 },
  { id: "Common.LastName", minWidth: 150 },
  { id: "Common.FirstName", minWidth: 150 },
  { id: "Common.Status", minWidth: 60 },
];

const PsychologistSelectorTable: React.FC<PsychologistSelectorTableProps> = ({
  psychologists,
  page,
  setPage,
  total,
  isFetching,
  hasNextPage,
  selectedPsychologist,
  setSelectedPsychologist,
  setSearch,
}) => {
  const { t } = useTranslation();
  const refCancel = useRef<HTMLButtonElement>(null);

  const getStatusIcon = (status: string, key: string) => {
    const icons = {
      [STATUS_TYPE.INACTIVE]: <PersonOffOutlined key={key} />,
      [STATUS_TYPE.ACTIVE_NOT_ACCEPTING]: <PersonRemoveOutlined key={key} />,
      [STATUS_TYPE.ACTIVE_ACCEPTING]: <PersonAddOutlined key={key} />,
    };
    return icons[status] || null;
  };

  const getStatus = (userTags: ExpertTag[]) => (
    <div>
      {userTags
        .filter((tag) => tag.tagType === TAG_TYPES.PSYCHOLOGIST_STATUS)
        .map((tag) => getStatusIcon(tag.tagName, tag.description))}
    </div>
  );
  const onSearchChange = useDebouncedCallback((searchValue: string) => {
    setSearch(searchValue);
    setPage(0);
  }, 500);
  return (
    <>
      <div className="flex">
        <SearchBox
          onChange={onSearchChange}
          placeholder={t("PsychologistSelection.SearchPlaceholder")}
          refCancel={refCancel}
          className="w-1/2"
        />
        <TablePagination
          component="div"
          className="ml-auto -mr-2 w-1/2"
          count={total}
          rowsPerPage={smallTablePageSize}
          rowsPerPageOptions={[smallTablePageSize]}
          page={page}
          onPageChange={(_, newPage) => setPage(newPage)}
          slotProps={{
            actions: {
              nextButton: { disabled: isFetching || !hasNextPage },
              previousButton: { disabled: isFetching || page === 0 },
            },
          }}
        />
      </div>
      {!isFetching && (
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    style={{ minWidth: column.minWidth }}
                    className="py-2 px-0"
                  >
                    <p className="sub1">{t(column.id)}</p>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {psychologists.map((psychologist) => (
                <TableRow
                  hover
                  tabIndex={-1}
                  key={psychologist.id}
                  id={psychologist.id}
                  classes={{
                    root: "opacity-70 cursor-pointer",
                  }}
                  onClick={() => setSelectedPsychologist(psychologist)}
                  selected={psychologist === selectedPsychologist}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      checked={psychologist === selectedPsychologist}
                    />
                  </TableCell>

                  <TableCell className="p-0">
                    <p className="sub1 hover:text-blue-500">
                      {psychologist.lastName}
                    </p>
                  </TableCell>
                  <TableCell className="p-0">
                    <p className="sub1 hover:text-blue-500">
                      {psychologist.firstName}
                    </p>
                  </TableCell>
                  <TableCell className="p-0">
                    <p className="sub1 hover:text-blue-500 text-center">
                      {getStatus(psychologist.tags)}
                    </p>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {isFetching && (
        <div className="flex w-full justify-center">
          <Spinner />
        </div>
      )}
    </>
  );
};

export default withSnackbar(PsychologistSelectorTable);
