/* eslint-disable react/prop-types */
import {
  Box,
  Grid,
  Icon,
  Typography,
  useMediaQuery,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
} from "@mui/material";
import { alpha } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import authenticationService from "@src/services/authentication.service";
import { useDebouncedCallback } from "use-debounce";
import AddPsychologistButton from "@src/components/AddPsychologistButton";
import ClearSearchButton from "@src/components/ClearSearchButton";
import FilterButton from "@src/components/FilterButton";
import FilterPsychologistsDialog from "@src/components/FilterPsychologistsDialog";
import NothingFound from "@src/components/NothingFound";
import PageTitle from "@src/components/PageTitle";
import PsychologistDetails from "@src/components/PsychologistDetails";
import SearchBox from "@src/components/SearchBox";
import { ReactComponent as WomanWebsite } from "@src/components/images/dashboard_home_background.svg";
import { SESSION_TYPE, STATUS_TYPE, TAG_TYPES, pageSize } from "@src/constants";
import { usePsychologistsQuery } from "@src/queries/experts";
import { isEmptyQueryResult } from "@src/queries/utils";
import { colors } from "@src/theme";
import { IconSessionLanguage } from "@src/components/Icons/IconSessionLanguage";

type ColumnProps = {
  id: string;
  minWidth: number;
};
const columns: ColumnProps[] = [
  { id: "Common.Name", minWidth: 100 },
  { id: "Common.Agenda", minWidth: 60 },
  { id: "Common.Status", minWidth: 60 },
  { id: "Common.Type", minWidth: 75 },
  { id: "Common.Language", minWidth: 200 },
];

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    padding: spacing(2, 3),
    borderRadius: 16,
    flexGrow: 1,
  },
  clientLink: {
    color: colors.primaryDarkBlue,
    textDecoration: "none",
  },
  searchBox: {
    marginRight: spacing(3),
    width: "unset",
    flexGrow: 1,
  },
  clientsIcon: {
    marginRight: spacing(1),
  },
  cell: {
    padding: spacing(2, 0),
  },
  row: {
    opacity: 0.7,
    cursor: "pointer",
  },
  hover: {
    "&:hover": {
      backgroundColor: `${alpha(colors.primaryBlue, 0.1)} !important`,
      borderRadius: 4,
    },
  },
  tableContainer: {},
  icon: {
    marginLeft: spacing(1),
  },
  grid: {
    display: "flex",
  },
  cellContain: {
    "$hover:hover &": {
      color: colors.secondaryBlue,
    },
  },
  cellContainEmail: {
    "$hover:hover &": {
      color: colors.secondaryBlue,
    },
    textDecoration: "underline",
  },
  image: {
    marginLeft: spacing(4),
    width: 250,
    height: 300,
  },

  boxTypes: {
    "& > span:not(:first-child)": {
      marginLeft: spacing(2),
    },
  },
}));

const PsychologistsList: React.FC = () => {
  const isSmallScreen = useMediaQuery((x: Theme) => x.breakpoints.down("lg"));
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState();
  const [psychologistId, setPsychologistId] = useState("");
  const [isImageVisible, setIsImageVisible] = useState(true);
  const [viewUpdated, setViewUpdated] = useState(false);
  const [isPsychologistFilterOpen, setIsPsychologistFilterOpen] =
    useState(false);
  const [filteringThemesAndTags, setFilteringThemesAndTags] = useState({
    tags: [],
    themes: [],
  });
  const refCancel = useRef();
  const { isManagerOrAdminOrSupport } = authenticationService;

  const onSearchChange = useDebouncedCallback((searchValue) => {
    setSearch(searchValue);
    setPage(0);
  }, 500);

  const { data, isFetching, refetch } = usePsychologistsQuery(
    { page, search },
    filteringThemesAndTags,
  );

  const setFilters = (tags = [], themes = []) => {
    setFilteringThemesAndTags({ tags, themes });
  };

  useEffect(() => {
    refetch();
  }, [psychologistId, refetch, viewUpdated]);

  const classes = useStyles();
  const { t } = useTranslation();

  const {
    data: users,
    pagination: { total, hasNextPage },
  } = data;

  const getStatusIcon = (status, key) => {
    switch (status) {
      case STATUS_TYPE.INACTIVE:
        return (
          <span key={key} className="material-icons-outlined">
            person_off
          </span>
        );
      case STATUS_TYPE.ACTIVE_NOT_ACCEPTING:
        return (
          <span key={key} className="material-icons-outlined">
            person_remove
          </span>
        );
      case STATUS_TYPE.ACTIVE_ACCEPTING:
        return (
          <span key={key} className="material-icons-outlined">
            person_add
          </span>
        );
      default:
        return null;
    }
  };

  const getTypeIcon = (type, key) => {
    switch (type) {
      case SESSION_TYPE.GENERAL:
        return (
          <span key={key} className="material-icons-outlined">
            forum
          </span>
        );
      case SESSION_TYPE.MINDFULNESS:
        return (
          <span key={key} className="material-icons-outlined">
            spa
          </span>
        );
      default:
        return null;
    }
  };

  const getStatus = (userTags) => {
    const statusTags = userTags.filter(
      (tag) => tag.tagType === TAG_TYPES.PSYCHOLOGIST_STATUS,
    );
    return (
      <Box>
        {statusTags.length > 0 &&
          statusTags.map((tag) => getStatusIcon(tag.tagName, tag.description))}
      </Box>
    );
  };

  const getType = (userTags) => {
    const typeTags = userTags.filter(
      (tag: { tagType: string }) => tag.tagType === TAG_TYPES.SESSION_TYPE,
    );
    return (
      <Box className={classes.boxTypes}>
        {typeTags.length > 0 &&
          typeTags.map((tag) => getTypeIcon(tag.tagName, tag.description))}
      </Box>
    );
  };

  const getAgendaIcon = (agenda) => {
    if (agenda) {
      return <Icon>check</Icon>;
    }
    return null;
  };

  const getLanguage = (userTags) => {
    const languageTags = userTags.filter(
      (tag) => tag.tagType === TAG_TYPES.PSYCHOLOGIST_LANGUAGE,
    );
    return (
      <div className="flex flex-row gap-1">
        {languageTags.map((tag) => (
          <IconSessionLanguage
            key={tag.tagName}
            language={tag.tagName}
            tooltip={tag.description}
          />
        ))}
      </div>
    );
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Box display="flex">
          <SearchBox
            onChange={onSearchChange}
            placeholder={t("Psychologists.List.SearchPlaceholder")}
            refCancel={refCancel}
            className={classes.searchBox}
          />
          <ClearSearchButton refCancel={refCancel} onChange={onSearchChange} />
          <FilterButton
            onClick={() => {
              setIsPsychologistFilterOpen(true);
            }}
            numberOfFilters={
              filteringThemesAndTags.tags.length +
              filteringThemesAndTags.themes.length
            }
          />
          <FilterPsychologistsDialog
            isEdit
            open={isPsychologistFilterOpen}
            onClose={() => {
              setIsPsychologistFilterOpen(false);
            }}
            setFilters={setFilters}
          />
          {isManagerOrAdminOrSupport() && (
            <AddPsychologistButton data-cy="add-psych-button" />
          )}
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={!isSmallScreen && isImageVisible ? 9 : 12}>
            {isEmptyQueryResult(data) ? (
              <NothingFound translationKey="Clients.List.NotFound" />
            ) : (
              <Grid item xs={12} className={classes.grid} data-cy="psych-table">
                <Grid container spacing={4}>
                  <Grid item xs={psychologistId ? 9 : 12}>
                    <Paper className={classes.root}>
                      <Box display="flex" alignItems="center">
                        <Icon className={classes.clientsIcon}>psychology</Icon>
                        <h5 className="h5">{t("Psychologists.Pagetitle")}</h5>
                        <TablePagination
                          component="div"
                          style={{
                            marginLeft: "auto",
                            marginRight: -8,
                          }}
                          count={total}
                          rowsPerPage={pageSize}
                          rowsPerPageOptions={[10]}
                          page={page}
                          onPageChange={(_, newPage) => setPage(newPage)}
                          nextIconButtonProps={{
                            disabled: isFetching || !hasNextPage,
                          }}
                          backIconButtonProps={{
                            disabled: isFetching || page === 0,
                          }}
                        />
                      </Box>
                      <TableContainer>
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            <TableRow>
                              {columns.map((column) => (
                                <TableCell
                                  key={column.id}
                                  style={{ minWidth: column.minWidth }}
                                  className={classes.cell}
                                >
                                  <Typography variant="subtitle1">
                                    {t(column.id)}
                                  </Typography>
                                </TableCell>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {users.map((user) => (
                              <TableRow
                                hover
                                role="checkbox"
                                tabIndex={-1}
                                key={user.id}
                                classes={{
                                  hover: classes.hover,
                                  root: classes.row,
                                }}
                                onClick={() => {
                                  setPsychologistId(user.id);
                                  setIsImageVisible(false);
                                }}
                              >
                                <TableCell className={classes.cell}>
                                  <Typography
                                    variant="subtitle1"
                                    className={classes.cellContain}
                                  >
                                    {`${user.firstName}, ${user.lastName}`}
                                  </Typography>
                                  <Typography
                                    variant="subtitle1"
                                    className={classes.cellContainEmail}
                                  >
                                    {user.email}
                                  </Typography>
                                </TableCell>
                                <TableCell className={classes.cell}>
                                  <div className={classes.cellContain}>
                                    {getAgendaIcon(user.hasLinkedCalendar)}
                                  </div>
                                </TableCell>
                                <TableCell className={classes.cell}>
                                  <div className={classes.cellContain}>
                                    {getStatus(user.tags)}
                                  </div>
                                </TableCell>
                                <TableCell className={classes.cell}>
                                  <Box display="flex">
                                    <div className={classes.cellContain}>
                                      {getType(user.tags)}
                                    </div>
                                  </Box>
                                </TableCell>
                                <TableCell className={classes.cell}>
                                  <Box display="flex">
                                    <div className={classes.cellContain}>
                                      {getLanguage(user.tags)}
                                    </div>
                                  </Box>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Paper>
                  </Grid>
                  <Grid item xs={3}>
                    {psychologistId && (
                      <PsychologistDetails
                        viewUpdated={viewUpdated}
                        setViewUpdated={setViewUpdated}
                        refetchPsychologistsList={() => {
                          refetch();
                        }}
                        psychologistId={psychologistId}
                        isEditAllowed={isManagerOrAdminOrSupport()}
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
          {!isSmallScreen && isImageVisible && (
            <Grid item xs={3}>
              <WomanWebsite className={classes.image} />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

const PsychologistsView = () => {
  const { t } = useTranslation();
  return (
    <>
      <PageTitle title={t("Psychologists.Pagetitle")} />
      <PsychologistsList />
    </>
  );
};

export default PsychologistsView;
